import * as React from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Link } from "gatsby"
import { Heading } from "@chakra-ui/react"

const shortcodes = {
  Link,
  h1: <Heading as="h1" fontSize="xl" />,
  h2: <Heading as="h2" fontSize="xl" />,
} // Provide common components here

export default function PageTemplate({ data: { mdx } }) {
  return (
    <div>
      <Heading>{mdx.frontmatter.title}</Heading>
      <MDXProvider components={shortcodes}>
        <MDXRenderer>{mdx.body}</MDXRenderer>
      </MDXProvider>
    </div>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      timeToRead
      frontmatter {
        title
      }
    }
  }
`
